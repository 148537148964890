import { WhiteContainer } from "components/container";
import PageTitle from "components/page_title/PageTitle";
import BigPersonCard from "components/person-cards/BigPersonCard";
import SmallPersonCard from "components/person-cards/SmallPersonCard";
import T from "locales";
import { Col, Row } from "react-bootstrap";
import { ADMINISTRATION, COUNCIL, OTHER } from "./texts";

const PersonnelPage = () => {
  return (
    <>
      <PageTitle title={<T k="personnel_title" />} />
      <WhiteContainer>
        <Row className="mt-5 mb-5">
          <Col>
            <h2>
              <T k="personnel_administration" />
            </h2>
          </Col>
        </Row>
        <Row>
          <Col xs="6" md="3" lg="2">
            <BigPersonCard {...ADMINISTRATION.main[0]} />
          </Col>
          <Col xs="6" md="3" lg={{ span: 2, offset: 1 }}>
            <BigPersonCard {...ADMINISTRATION.main[1]} />
          </Col>
          <Col
            xs="12"
            md={{ span: 5, offset: 1 }}
            lg={{ span: 4, offset: 1 }}
            className="mt-5 mt-md-0"
          >
            {ADMINISTRATION.secondary.map((item, i) => (
              <div key={i} className="mb-4">
                <SmallPersonCard {...item} />
              </div>
            ))}
          </Col>
        </Row>
        <Row className="mt-5 mb-5">
          <Col>
            <h2>
              <T k="personnel_council" />
            </h2>
            <p>
              <T k="personnel_council_desc" />
            </p>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col xs="6" md="3" lg="2">
            <BigPersonCard {...COUNCIL.main[0]} />
          </Col>
          <Col xs="6" md={{ span: 3, offset: 1 }} lg={{ span: 2, offset: 1 }}>
            <BigPersonCard {...COUNCIL.main[1]} />
          </Col>
          <Col
            xs="12"
            md="12"
            lg={{ span: 6, offset: 1 }}
            className="mt-5 mt-lg-0"
          >
            <Row>
              {COUNCIL.secondary
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((item, i) => (
                  <Col xs="12" md="6" className="mb-4" key={i}>
                    <SmallPersonCard {...item} key={i} />
                  </Col>
                ))}
            </Row>
          </Col>
        </Row>
        <Row className="mt-5 mb-5">
          <Col>
            <h2>
              <T k="other" />
            </h2>
          </Col>
        </Row>
        <Row className="mb-5">
          {OTHER.sort((a, b) => a.name.localeCompare(b.name)).map((item, i) => (
            <Col xs="12" md="6" lg="4" className="mb-4" key={i}>
              <SmallPersonCard {...item} key={i} />
            </Col>
          ))}
        </Row>
      </WhiteContainer>
    </>
  );
};

export default PersonnelPage;
